import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";

const FestivalProgrammes = (props) => {

	const showDownloadMessage = props.showDownloadMessage && props.showDownloadMessage === true

	const data = useStaticQuery(graphql`{
  programmeMainImg: file(
    relativePath: {eq: "Boswell-Book-Festival-Online-2021-Cover-001.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 300, placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
    }
  }
  programmeChildrensImg: file(
    relativePath: {eq: "Boswell-Schools-and-Family-Online-2021-Cover-001.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 300, placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
    }
  }
}
`)

	const mainProgramme = 'Boswell Book Festival Online 2021 Programme 011.pdf'
	const childrensProgramme = 'Boswell Schools and Family 2021 Online Programme 002.pdf'

	return <>
        <div className={`flex items-start justify-around`}>
            <a
                href={`/${mainProgramme}`}
                title={`Download the programme for the Boswell Book Festival 2021`}
                target={`_blank`}
                rel={`noopener noreferrer`}
            >
                <GatsbyImage
                    image={data.programmeMainImg.childImageSharp.gatsbyImageData}
                    objectFit={`cover`}
                    className={`w-24 sm:w-32 lg:w-40 xl:w-48 2xl:w-64 shadow-md transform hover:scale-105 hover:shadow-lg transition-all`} />
            </a>
            <a
                href={`/${childrensProgramme}`}
                title={`Download the programme for the Boswell Schools & Family Festival 2021`}
                target={`_blank`}
                rel={`noopener noreferrer`}
            >
                <GatsbyImage
                    image={data.programmeChildrensImg.childImageSharp.gatsbyImageData}
                    objectFit={`cover`}
                    className={`w-24 sm:w-32 lg:w-40 xl:w-48 2xl:w-64 shadow-md transform hover:scale-105 hover:shadow-lg transition-all`} />
            </a>
        </div>
        { showDownloadMessage && <p className={`text-lg mt-4`}>Download the programmes to see the full list of events including <strong>Festival Plus+</strong></p> }
    </>;
}

export default FestivalProgrammes
