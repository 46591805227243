import * as React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout/Layout'
import Twenty21 from '../components/Page/2021'
import BannerImage from '../components/Page/BannerImage'
import FestivalProgrammes from '../components/Event/FestivalProgrammes'


const thisYearPage = ({data}) => {
	return (
		<Layout>
			<BannerImage image={data.file.childImageSharp.gatsbyImageData} />
			<section className="bg-boswell-handwriting pt-12 pb-16">
				<p className={`text-3xl px-12`}>Please <Link to={`/2021/box-office`} className={`link`}>register through the box office</Link> for all events.</p>
				<div className={`flex flex-row items-start flex-wrap `}>
					<div className="md:w-1/2">
						<Twenty21 />
					</div>
					<div className="p-4 md:p-12 md:w-1/2">
						<FestivalProgrammes showDownloadMessage={true} />
					</div>
				</div>
			</section>
		</Layout>
    );
}

export const query = graphql`{
  file(relativePath: {eq: "banner/Boswell-Book-Festival-2021.jpg"}) {
    childImageSharp {
      gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
    }
  }
}
`

export default thisYearPage
