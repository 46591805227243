import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

const Twenty21 = () => {
	const data = useStaticQuery(graphql`
		query {
			file(relativePath: {eq:"2021.md"}) {
				childMarkdownRemark {
					html
					frontmatter {
						id
						title
					}
				}
			}
		}
	`)

	return (
		<section className="p-4 md:p-12 min-h-screen-50 bg-boswell-handwriting">
			<div className="markdown-content" dangerouslySetInnerHTML={{ __html: data.file.childMarkdownRemark.html }}></div>
		</section>
	)
}

export default Twenty21
